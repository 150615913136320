<template>
  <div class="filosofia-body">
    <div id="headerHome" class="col-sm-12 col-md-12 color-bg-1">
      <HeaderHome :name="user.fullname" />
    </div>

    <div class="filosofia">
      <div v-if="backgr !== 'No se encontraron coincidencias'">
        <img :src="urlpath.url() + '/get-cabeceras-image/' + backgr.image" alt="pc" class="pc imgx">
        <img :src="urlpath.url() + '/get-cabeceras-image/' + backgr.image_mv" alt="mo" class="mo imgx">
      </div><!---->
      <div class="noav" v-else>

      </div>
      <div class="filo-header">
        <h1 class="h-tra">FILOSOFÍA</h1>
        <h1>FILOSOFÍA</h1>
        <h6>CIMARRONES DE SONORA</h6>
      </div>
      <div class="filo-cont">
        <div class="sec uno">
          <div class="sec-texto">
            <h4 class="filo-titulo">Misión</h4>
            <h6 class="filo-parrafo">
              Somos un club de fútbol profesional joven que representa al estado
              de Sonora, comprometidos a lograr el éxito deportivo basándonos en
              grandes valores y sentido de responsabilidad social con los
              deportistas y la comunidad en general.
            </h6>
          </div>
          <img src="../assets/filo1.png" alt="" class="filo-img" />
          <img src="../assets/pts-red.png" alt="" class="redpoints" />
        </div>
        <div class="sec due">
          <img src="../assets/filo2.png" alt="" class="filo-img" />
          <div class="sec-texto">
            <h4 class="filo-titulo">Visión</h4>
            <h6 class="filo-parrafo">
              Participar en la primera división del fútbol mexicano siendo
              reconocidos como una institución referente en la generación y
              desarrollo integral de talento en la región noroeste del país,
              impulsando activamente a los futbolistas para alcanzar su mayor
              nivel en el ámbito personal y profesional.
            </h6>
          </div>
        </div>
        <div class="sec tre">
          <img src="../assets/objetivos.png" alt="" class="head-obje" />
          <img src="../assets/objetivos-m.png" alt="" class="head-obje-m" />
          <div class="sec-texto">
            <div class="filo-list">
              <h4 class="filo-titulo">1</h4>
              <h6 class="filo-parrafo">
                Posicionar al club como el único equipo profesional de fútbol en
                la región, que opera con altos estándares de calidad dentro de
                su estructura y encada una de sus categorías.
              </h6>
            </div>
            <div class="filo-list">
              <h4 class="filo-titulo">2</h4>
              <h6 class="filo-parrafo">
                Lograr ser parte de la identidad de la afición sonorense a
                través de la creación de vínculos emocionales entre el club y
                los fanáticos del fútbol.
              </h6>
            </div>
            <div class="filo-list">
              <h4 class="filo-titulo">3</h4>
              <h6 class="filo-parrafo">
                Brindar un gran espectáculo deportivo y de entretenimiento para
                la sociedad en un espacio seguro y divertido para los seguidores
                del deporte.
              </h6>
            </div>
            <div class="filo-list">
              <h4 class="filo-titulo">4</h4>
              <h6 class="filo-parrafo">
                Participar activamente en distintos ámbitos sociales mediante
                acciones de esponsabilidad que impacten positivamente a la
                comunidad.
              </h6>
            </div>
            <div class="filo-list">
              <h4 class="filo-titulo">5</h4>
              <h6 class="filo-parrafo">
                Ser una institución reconocida por su alto porcentaje de
                formación y exportación de talentos regionales.
              </h6>
            </div>
          </div>
          <div class="sec-texto mo uno">
            <div class="filo-list">
              <h4 class="filo-titulo">1</h4>
              <h6 class="filo-parrafo">
                Posicionar al club como el único equipo profesional de fútbol en
                la región, que opera con altos estándares de calidad dentro de
                su estructura y encada una de sus categorías.
              </h6>
            </div>
            <div class="filo-list">
              <h4 class="filo-titulo">2</h4>
              <h6 class="filo-parrafo">
                Lograr ser parte de la identidad de la afición sonorense a
                través de la creación de vínculos emocionales entre el club y
                los fanáticos del fútbol.
              </h6>
            </div>
          </div>
          <img src="../assets/filo3.png" alt="" class="filo-img" />
          <div class="sec-texto mo">
            <div class="filo-list">
              <h4 class="filo-titulo">3</h4>
              <h6 class="filo-parrafo">
                Brindar un gran espectáculo deportivo y de entretenimiento para
                la sociedad en un espacio seguro y divertido para los seguidores
                del deporte.
              </h6>
            </div>
            <div class="filo-list">
              <h4 class="filo-titulo">4</h4>
              <h6 class="filo-parrafo">
                Participar activamente en distintos ámbitos sociales mediante
                acciones de esponsabilidad que impacten positivamente a la
                comunidad.
              </h6>
            </div>
            <div class="filo-list">
              <h4 class="filo-titulo">5</h4>
              <h6 class="filo-parrafo">
                Ser una institución reconocida por su alto porcentaje de
                formación y exportación de talentos regionales.
              </h6>
            </div>
          </div>
        </div>
        <div class="sec quattro" id="Historia">
          <img src="../assets/filo4.png" alt="" class="filo-img" />
          <div class="sec-texto">
            <h4 class="filo-titulo">Historia</h4>
            <h6 class="filo-parrafo">
              El Club Cimarrones de Sonora nace en 2013 luego de fusionarse con
              el “Poblado Miguel Alemán FC”, un equipo de campeones a 60km de
              Hermosillo. Ocho años después, el Club ha cosechado dos elementos
              fundamentales: protagonismo en el futbol mexicano y el cariño de
              la afición Sonorense.
              <br />
              Nuestra casa es el Estadio “Héroe de Nacozari”, ubicado en
              Hermosillo, Sonora. Tiene un aforo para 18,000 espectadores,
              convirtiéndolo en el estadio más grande del estado. Fue inaugurado
              en 1985 y remodelado en 2014 para cumplir con todas las exigencias
              de la Federación Mexicana de Fútbol.
            </h6>
          </div>
        </div>
      </div>
    </div>

    <div id="footerSection">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../components/footer";
import HeaderHome from "../components/HeaderHome";
import { mapActions } from "vuex";
let urlpath = require('../global/url');
export default {
  data() {
    return {
      urlpath,
      backgr: []
    }
  },
  components: {
    HeaderHome,
    Footer,
  },
  async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
  async created() {
    let payload2 = {
      id: 'Filosofia',
      option: "cabeceras/section",
    };
    this.backgr = await this.getCabecerasById(payload2);
  },
  computed: {
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
  },
  methods: {
    ...mapActions("cabeceras", ["getCabecerasById"]),
  },

  /**titulo pagina */
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || 'Filosofía - Cimarrones de Sonora';
      }
    },
  }
};
</script>

<style>

img.pc.imgx { 
  display: block;
}
  img.mo.imgx { 
    display: none;
}
@media (max-width: 768px) {
img.pc.imgx { 
  display: none;
}
  img.mo.imgx {
    top: 21vw;
  display: block;
    height: 51.208vw;
}
}
</style>